<script setup>
const props = defineProps({
  url: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: '_blank',
  },
  title: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'button',
  },
  icon: {
    type: String,
    default: 'link-arrow-right',
  },
  activeOnlyIcon: {
    type: Boolean,
    default: false,
  },
});

const component = computed(() =>
  props.url ? resolveComponent('NuxtLink') : 'button',
);
</script>

<template>
  <Component
    :is="component"
    :to="url"
    :type="!props.url ? type : undefined"
    class="buttons-underlined"
    :class="{
      'buttons-underlined--active link': !activeOnlyIcon,
      'buttons-underlined--active-only-icon': activeOnlyIcon,
    }"
    :target="props.url ? target : undefined"
  >
    <div class="buttons-underlined__wrapper">
      <CIcon
        :name="icon"
        class="buttons-underlined__icon buttons-underlined__icon--left hidden-mobile"
      />

      <span class="buttons-underlined__font buttons-underlined__font--link">
        {{ $tp(title) }}
      </span>

      <CIcon
        :name="icon"
        :class="{ link: activeOnlyIcon }"
        class="buttons-underlined__icon buttons-underlined__icon--right"
        @pointerenter.stop
        @pointermove.stop
        @mouseenter.stop
        @mouseover.stop
        @mousemove.stop
      />
    </div>

    <span class="buttons-underlined__line buttons-underlined__line--1" />
    <span
      class="buttons-underlined__line buttons-underlined__line--2 hidden-mobile"
    />
  </Component>
</template>

<style scoped lang="scss">
.buttons-underlined {
  $parent: &;
  $gap-arrow: 44;

  position: relative;
  padding-bottom: em(19);
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding-bottom: em(13);
  }

  &__wrapper {
    position: relative;
    padding-right: em($gap-arrow);

    @include media-breakpoint-down(sm) {
      padding-right: em(39);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include box(em(24));

    @include media-breakpoint-up(sm) {
      transition: transform $time-slow;
      will-change: transform;
    }

    @include media-breakpoint-down(sm) {
      @include box(em(19));
    }

    &--left {
      transform: translate(em(-$gap-arrow), -50%);
    }

    &--right {
      right: 0;
    }
  }

  &__font {
    &--link {
      font-size: em(20);
      line-height: em(22, 20);

      @include media-breakpoint-up(sm) {
        font-size: em(32);
        line-height: em(35, 32);
        display: block;
        transition: transform $time-slow;
        will-change: transform;
      }
    }
  }

  &__line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform $time-slow ease-out;
    will-change: transform;

    &--1 {
      @include media-breakpoint-up(sm) {
        transition-delay: $time-fast;
        transform-origin: 100% 100%;
      }
    }

    &--2 {
      @include media-breakpoint-up(sm) {
        transform: scaleX(0);
        transform-origin: 0 100%;
      }
    }
  }

  &--active {
    @include hover-active-focus {
      #{$parent} {
        &__icon {
          &--left {
            @include media-breakpoint-up(sm) {
              transform: translateY(-50%);
            }
          }

          &--right {
            @include media-breakpoint-up(sm) {
              transform: translate(100%, -50%);
            }
          }
        }

        &__font {
          &--link {
            @include media-breakpoint-up(sm) {
              transform: translateX(em($gap-arrow, 32));
            }
          }
        }

        &__line {
          &--1 {
            @include media-breakpoint-up(sm) {
              transition-delay: 0s;
              transform: scaleX(0);
            }
          }

          &--2 {
            @include media-breakpoint-up(sm) {
              transition-delay: $time-fast;
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }

  &--active-only-icon {
    pointer-events: none;

    #{$parent}__icon {
      pointer-events: auto;

      @include media-breakpoint-up(sm) {
        transition: color $time-normal;
      }
    }
  }
}
</style>
